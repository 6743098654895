import { FC, ReactNode } from "react";
import { useAuth } from "../../contexts/AuthProvider";

interface AuthorizedProps {
  children: ReactNode;
}

const Authorized: FC<AuthorizedProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (currentUser) {
    return <>{children}</>;
  }

  return <></>;
};

const Unauthorized: FC<AuthorizedProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <>{children}</>;
  }

  return <></>;
};

export { Authorized, Unauthorized };
