import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Authorized, Unauthorized } from "../features/session/Authorized";
import Markdown from "../components/common/Markdown";
import PageLayout from "../components/layouts/PageLayout";
import LoginForm from "../features/session/LoginForm";

const TopPage: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <div className="grid place-items-center justify-center items-center text-center">
        <p className="my-4 landing-intro">
          <Markdown>{t("top.landingIntro")}</Markdown>
        </p>
      </div>
      <Authorized>
        <Link className="btn btn-primary w-full mt-6" to="/speaks">
          {t("top.your-transcriptions-list")}
        </Link>
      </Authorized>
      <Unauthorized>
        <div className="text-center">
          <Markdown>{t("top.need login")}</Markdown>
          <LoginForm />
        </div>
      </Unauthorized>
    </PageLayout>
  );
};

export default TopPage;
