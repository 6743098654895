import {
  UserCircle,
  UserCirclePlus,
  SpinnerGap,
  Spinner,
} from "@phosphor-icons/react";
import { useAuth } from "../../contexts/AuthProvider";

interface UserIconProps {
  className?: string;
  size?: number;
  onClick?: () => void;
}

const UserIcon: React.FC<UserIconProps> = ({ className, size, onClick }) => {
  const { isLoading, currentUser } = useAuth();

  if (isLoading) {
    return (
      <Spinner
        className={className}
        size={size}
        weight="regular"
        onClick={onClick}
      >
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="1s"
          repeatCount="indefinite"
        ></animate>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1.5s"
          from="0 0 0"
          to="360 0 0"
          repeatCount="indefinite"
        ></animateTransform>
      </Spinner>
    );
  }

  if (currentUser) {
    return <UserCircle className={className} size={size} weight="fill" />;
  } else {
    return <UserCirclePlus className={className} size={size} weight="fill" />;
  }
};

export default UserIcon;
