import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import TopPage from "./pages/TopPage";
import PrivatePage from "./pages/PrivatePage";
import PublicPage from "./pages/PublicPage";
import SpeakListPage from "./pages/speaks/ListPage";
import SpeakItemPage from "./pages/speaks/ItemPage";
import SpeakNewPage from "./pages/speaks/NewPage";
import { ThemeProvider } from "./contexts/ThemeProvider";
import { AuthProvider } from "./contexts/AuthProvider";
import "./App.css";

const App: FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/private" element={<PrivatePage />} />
          <Route path="/public" element={<PublicPage />} />
          <Route path="/speaks" element={<SpeakListPage />} />
          <Route path="/speaks/new" element={<SpeakNewPage />} />
          <Route
            path="/speaks/:speakId"
            element={<SpeakItemPage />}
          />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
