import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Dropdown } from "react-daisyui";
import { useTranslation } from "react-i18next";

import ChangeDarkMode from "../../components/common/ChangeDarkMode";
import UserIcon from "../../features/session/UserIcon";
import LoginModal from "../../features/session/LoginModal";
import { useAuth } from "../../contexts/AuthProvider";

interface TopBarProps {
  children?: React.ReactNode;
}

const TopBar: FC<TopBarProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [showLogin, setShowLogin] = useState(false);

  const handleLogin = async () => {
    setShowLogin(true);
  };

  const handleClose = async () => {
    setShowLogin(false);
  };

  const handleLogout = async () => {
    await logout();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleSettings = () => {
    navigate("/user/settings");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const userIcon = currentUser ? (
    <Dropdown end={true} className="ml-5 mr-2">
      <Dropdown.Toggle button={false}>
        <UserIcon className="h-7 w-7 mr-2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="mt-2 bg-base-300">
        <li className="m-4 font-bold whitespace-nowrap">
          {currentUser?.displayName}
        </li>
        <Dropdown.Item className="whitespace-nowrap" onClick={handleSettings}>
          {t("session.Account settings")}
        </Dropdown.Item>
        <Dropdown.Item className="whitespace-nowrap" onClick={handleLogout}>
          {t("session.Logout")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <div onClick={handleLogin}>
      <UserIcon className="h-7 w-7 ml-5 mr-4" />
    </div>
  );

  return (
    <>
      <Navbar className="fixed top-0 z-100 bg-base-200 shadow">
        <Navbar.Start>
          <NavLink
            className="ml-4 text-xl font-semibold no-underline normal-case"
            to="/"
          >
            {t("common.AppName")}
          </NavLink>
        </Navbar.Start>
        <Navbar.Center>{children}</Navbar.Center>
        <Navbar.End>
          <ChangeDarkMode className="ml-5 h-6 w-6" />
          {userIcon}
        </Navbar.End>
      </Navbar>
      {showLogin && <LoginModal showClose={true} onClose={handleClose} />}
    </>
  );
};

export default TopBar;
