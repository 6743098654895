import { FC } from "react";
import PageLayout from "../components/layouts/PageLayout";
import LoginModal from "../features/session/LoginModal";

const LoginPage: FC = () => {
  return (
    <PageLayout>
      <LoginModal />
    </PageLayout>
  );
};

export default LoginPage;
