import { FC } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { Badge } from "react-daisyui"
import { useSpeaks } from "../../hooks/useSpeaks";
import { Utils } from "../../Utils";

interface SpeakListProps { }

const SpeakList: FC<SpeakListProps> = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { data: speaks, isLoading, isError, error } = useSpeaks(currentUser?.uid);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    console.error(error)
    return <span>Error occurred while loading posts</span>;
  }

  return (
    <div className="w-full grid grid-cols-1">
      <div className="w-full grid grid-cols-9 text-sm mb-2">
        <div className="col-span-4">タイトル</div>
        <div>状態</div>
        <div>カテゴリ</div>
        <div>言語</div>
        <div>作成日</div>
        <div>長さ</div>
      </div>

      {
        speaks?.map((speak) => (
          <Link
            to={`/speaks/${speak.id}`}
            className="w-full grid grid-cols-9 py-2 border-t hover:bg-slate-50"
            key={speak.id}
          >
            <div className="col-span-4">{speak.title}</div>
            <div><Badge className="text-xs" color="info">{t("transcription-status." + speak.transcriptionStatus)}</Badge></div>
            <div></div>
            <div>{t("speak-language." + speak.language)}</div>
            <div>
              {format(speak.createdAt.toDate(), 'yyyy/MM/dd hh:mm')}
            </div>
            <div>{speak.duration ? Utils.toMin(speak.duration) : "-"}</div>
          </Link>
        ))
      }
    </div >
  );
};

export default SpeakList;
