import { FC } from "react";
import PageLayout from "../components/layouts/PageLayout";

const PrivatePage: FC = () => (
  <PageLayout requiredAuth={true}>
    <div>It's protected page.</div>
  </PageLayout>
);

export default PrivatePage;
