import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import ReactPlayer from 'react-player'
import { Badge } from "react-daisyui";
import { useSpeak } from "../../hooks/useSpeak";
import { Utils } from "../../Utils";

interface SpeakItemProps {
  speakId: string;
}

const SpeakItem: FC<SpeakItemProps> = ({
  speakId,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const playerRef = useRef<ReactPlayer | null>(null);
  const { data: speak, isLoading, isError, error } = useSpeak(currentUser?.uid, speakId)
  const [playing, setPlaying] = useState<boolean>(false)

  const seekTo = (sec: number) => {
    playerRef.current?.seekTo(sec, 'seconds');
    setPlaying(true)
  }

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    console.error(error)
    return <span>Error: {(error as Error).message}</span>;
  }

  if (!speak) {
    return <Navigate to="/404" />;
  }

  const texts = speak.transcription ? JSON.parse(speak.transcription.content) : []
  console.log(texts)

  return (
    <>
      <div className="w-full my-5 pb-4">
        <h1 className="text-xl font-bold mb-2">{speak.title}</h1>
        <div className="mb-2">
          <Badge color="info">{t("transcription-status." + speak.transcriptionStatus)}</Badge>・<span>{speak.duration ? Utils.toMin(speak.duration) : "-"}</span>・<span>{t("speak-language." + speak.language)}</span>
        </div>

        <div>{speak.description}</div>
      </div>
      <div className="w-full grid grid-cols-2 gap-12">
        <div>
          <div className="font-bold mb-1">書き起こし</div>
          <hr className="mb-2" />
          {texts?.map((t: any, i: number) => (
            <div key={i} className="mb-4">
              <div className="mb-1"><span className="font-semibold">{t[2]}</span><span className="ml-2 text-slate-400">{Utils.convertTimeFormat(t[0])}</span></div>
              <div onClick={() => seekTo(t[0])} className="cursor-pointer">{t[3]}</div>
            </div>
          ))}
        </div>

        <div>
          <ReactPlayer url={speak.videoUrl} controls={true} ref={playerRef} playing={playing} width="100%" height="auto" />
        </div>
      </div>
    </>
  );
};

export default SpeakItem;
