import RequireAuth from "../../features/session/RequireAuth";
import TopBar from "../../features/session/TopBar";

interface PageLayoutProps {
  children: React.ReactNode;
  requiredAuth?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  className,
  requiredAuth = false,
  fullWidth = false
}) => {
  if (!fullWidth) {
    className += " container"
  }
  let contents = (
    <>
      <TopBar />
      <div className={`pt-16 pb-16 mx-auto px-8 ${className}`}>
        {children}
      </div>
    </>
  );

  if (requiredAuth) {
    return <RequireAuth>{contents}</RequireAuth>;
  } else {
    return <>{contents}</>;
  }
};

export default PageLayout;
