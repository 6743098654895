import "./init";
import {
  GoogleAuthProvider,
  getAuth,
  connectAuthEmulator,
} from "firebase/auth";

const auth = getAuth();

let authEmulatorHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;

if (authEmulatorHost) {
  connectAuthEmulator(auth, `http://${authEmulatorHost}`);
}

const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
