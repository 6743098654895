import { useAuth } from "../../contexts/AuthProvider";
import LoginPage from "../../pages/LoginPage";

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <>{currentUser ? children : <LoginPage />}</>;
};

export default RequireAuth;
