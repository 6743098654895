
export const Utils = {
  toMin(sec: number | undefined) {
    return Math.floor((sec || 0) / 60) + "分"
  },
  // 秒を 00:00:31 フォーマットに変える
  formatTime(sec: number) {
    const date = new Date(0);
    date.setSeconds(sec);
    return date.toISOString().substring(11, 11 + 8);
  },
  convertTimeFormat(input: string) {
    let [hours, minutes, secondsWithMillis] = input.split(':');
    // 秒の部分から小数点以降を削除
    let seconds = secondsWithMillis.split('.')[0];

    hours = hours.padStart(2, '0');
    minutes = minutes.padStart(2, '0');
    seconds = seconds.padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

}