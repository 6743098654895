import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Textarea, Button, Input } from "react-daisyui";
import FileUpload from "../../components/common/FileUpload";
import { storage } from "../../services/firebase/storageService";
import { ref, uploadBytes } from "firebase/storage";
import { useAuth } from "../../contexts/AuthProvider";
import { User } from "firebase/auth";
import { createSpeak, setPathToSpeak } from "../../hooks/createSpeak";

interface UploadFilesResult {
  processId: string;
}
async function uploadToStorage(file: File, path: string) {
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, file)
    .then((snapshot) => {
      console.log("アップロードに成功しました");
    })
    .catch((error) => {
      console.log("アップロードに失敗しました");
      console.error(error)
    });
}

async function register(
  files: File[],
  prompt: string,
  title: string,
  description: string,
  numberOfSpeakers: number,
  language: string,
  speakers: string[],
  user: User
): Promise<UploadFilesResult | undefined> {
  // ここから直接、firestore, storage のAPIを叩く
  const ref = await createSpeak(
    title,
    description,
    numberOfSpeakers,
    speakers.filter((e: string) => e !== ""),
    language,
    user.uid,
  )

  const ext = files[0].name.split('.').pop()
  const path = `upload_videos/${ref.id}.${ext}`
  uploadToStorage(files[0], path)
  setPathToSpeak(ref, path)

  return { processId: ref.id };;
}

const Uploading: FC = () => <div>Uploading...</div>;

interface NewFormProps {
  onFinished: (processId: string) => void;
}

const NewForm: FC<NewFormProps> = ({ onFinished }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [numberOfSpeakers, setNmberOfSpeakers] = useState<number>(2);
  const [speaker1, setSpeaker1] = useState<string>("");
  const [speaker2, setSpeaker2] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const canSubmit = files.length > 0;

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) =>
    (async (event) => {
      event.preventDefault();
      if (uploading || !currentUser) return;

      setUploading(true);
      const result = await register(files, prompt, title, description, numberOfSpeakers, language, [speaker1, speaker2], currentUser);
      setUploading(false);

      if (result) {
        onFinished(result.processId);
      }
    })(event);

  if (uploading) return <Uploading />;

  return (
    <>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <div className="flex w-full component-preview">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">タイトル</span>
              </label>
              <Input value={title}
                onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>
          <div className="flex w-full component-preview">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">説明</span>
              </label>
              <Textarea value={description}
                onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>
          <div className="flex w-full component-preview">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">スピーカー人数</span>
              </label>
              <Input value={numberOfSpeakers} type="number" min="1"
                onChange={(e) => setNmberOfSpeakers(Number(e.target.value))} />
            </div>
          </div>
          <div>
            <div className="flex w-full component-preview">
              <div className="form-control w-full">
                <label className="label flex-col items-start">
                  <span className="label-text">スピーカー名</span>
                  <span className="label-text-alt">入力された順に、書き起こしの話者に対応させます。</span>
                </label>
                <Input value={speaker1} className="mb-2"
                  onChange={(e) => setSpeaker1(e.target.value)} />
                <Input value={speaker2} className="mb-2"
                  onChange={(e) => setSpeaker2(e.target.value)} />
              </div>
            </div>

          </div>
        </div>

        <div>
          <label className="label flex-col items-start">
            <span className="label-text">{t("upload.file")}</span>
            <span className="label-text-alt">{t("upload.file-desc")}</span>
          </label>
          <FileUpload
            className="w-full mt-2"
            onDrop={(files) => {
              setFiles(files);
            }}
          />
          <h2 className="text-xl font-bold mt-6">{t("upload.prompt")}</h2>
          <div className="mt-2">{t("upload.prompt-desc")}</div>
          <Textarea
            className="w-full mt-2"
            value={prompt}
            placeholder={t("upload.prompt-placeholder") ?? ""}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
      </div >
      <Button
        className="w-full mt-6 mb-6"
        disabled={!canSubmit}
        onClick={handleSubmit}
      >
        {t("upload.submit")}
      </Button>
    </>
  );
};

export default NewForm;
