import { useQuery } from "@tanstack/react-query";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { Speak, speakConverter, transcriptionConverter } from "../model";
import { firestore } from "../services/firebase/firestoreService";
import { storage } from "../services/firebase/storageService";


export const useSpeak = (userUid: string | undefined, id: string) => {
  return useQuery([userUid, 'speak', id], fetchDataWithTranscription);
};

const fetchDataWithTranscription = async ({ queryKey }: any): Promise<Speak | undefined> => {
  const speakId = queryKey[2];

  const speakRef = doc(firestore, "speaks", speakId).withConverter(speakConverter);
  // 同じIDを使う
  const transcriptionRef = doc(firestore, "transcriptions", speakId).withConverter(transcriptionConverter);

  const snapshots = await Promise.all([getDoc(speakRef), getDoc(transcriptionRef)]);

  const speak = snapshots[0].data()
  const transcription = snapshots[1].data()

  if (speak) {
    speak.videoUrl = await getDownloadURL(ref(storage, speak.videoPath))
    speak.transcription = transcription
  }
  return speak;
}