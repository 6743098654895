import "./init";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firestore = getFirestore();

let firestoreEmulatorHost =
  process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_ADDRESS;

if (firestoreEmulatorHost) {
  const [host, port] = firestoreEmulatorHost.split(":");
  connectFirestoreEmulator(firestore, host, parseInt(port));
}

export { firestore };
