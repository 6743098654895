import { useQuery } from "@tanstack/react-query";
import { query, collection, where, orderBy, getDocs } from "firebase/firestore";
import { Speak, speakConverter } from "../model";
import { firestore } from "../services/firebase/firestoreService";


export const useSpeaks = (userUid: string | undefined) => {
  return useQuery([userUid, 'speaks'], fetchInfo);
};

const fetchInfo = async ({ queryKey }: any): Promise<Speak[]> => {
  const uid = queryKey[0];
  const speakQuery = query(
    collection(firestore, "speaks").withConverter(speakConverter),
    where("userId", "==", uid),
    orderBy("createdAt", "desc")
  );
  const snapshot = await getDocs(speakQuery);
  console.log(snapshot)
  return snapshot.docs.map((doc) => doc.data())
};
