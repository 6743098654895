import React, { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import { auth, googleProvider } from "../services/firebase/authService";

interface AuthContextType {
  currentUser?: User;
  isLoading: boolean;
  username?: string;

  login: (onChange?: (user?: User) => void) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: undefined,
  isLoading: true,

  login: async (onChange?: (user?: User) => void) => { },
  logout: async () => { },
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [handleChange, setHandleChange] = useState<(user?: User) => void>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) =>
      (async () => {
        try {
          if (user) {
            console.log(user)
            const response = await fetch(
              `${process.env
                .REACT_APP_BACKEND_URL!}/session/verify-firebase-token`,
              {
                method: "POST",
                body: JSON.stringify({ id_token: await user.getIdToken() }),
                headers: {
                  "Content-Type": "application/json",
                },
                mode: "cors",
                credentials: "include",
              }
            );
            const responseBody = await response.json();
            if (
              ![200, 201].includes(response.status) ||
              responseBody.uid !== user.uid
            ) {
              user = null;
              await signOut(auth);
            }
          }
        } catch (e) {
          user = null;
        }
        setIsLoading(false);
        setCurrentUser(user ?? undefined);
        handleChange && handleChange(user ?? undefined);
      })()
    );
    return unsubscribe;
  }, [handleChange]);

  const login = async (onChange?: (user?: User) => void) => {
    setHandleChange(onChange);
    setIsLoading(true);
    await signInWithPopup(auth, googleProvider);
  };

  const logout = async () => {
    await signOut(auth);
  };

  const value: AuthContextType = { currentUser, isLoading, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
