import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PageLayout from "../../components/layouts/PageLayout";
import SpeakItem from "../../features/speaks/Item";

interface ItemPageProps {
  speakId: string;
}

const ItemPage: FC = () => {
  const { t } = useTranslation();
  const { speakId } = useParams<
    keyof ItemPageProps
  >() as ItemPageProps;

  return (
    <PageLayout requiredAuth={true} fullWidth={true}>
      <SpeakItem speakId={speakId} />
    </PageLayout>
  );
};

export default ItemPage;
