import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

const linkBlock = (props: { href: string; children: React.ReactNode }) => {
  const { href, children } = props;

  if (href.match("http")) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  if (href.slice(0, 1) === "#") {
    return <a href={href}>{children}</a>;
  }
  return <Link to={href}>{children}</Link>;
};

interface MarkdownProps {
  children: string;
}

const Markdown: React.FC<MarkdownProps> = ({ children }) => {
  return (
    <ReactMarkdown
      className="i18n-markdown"
      components={
        {
          a: linkBlock,
        } as any
      }
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
