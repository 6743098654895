import React, { FC } from "react";
import { useDropzone } from "react-dropzone";
import { CloudArrowUp } from "@phosphor-icons/react";

interface FileUploadPrpos {
  className?: string;
  onDrop?: (files: File[]) => void;
}

const FileUpload: FC<FileUploadPrpos> = ({ className, onDrop }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <div {...getRootProps({ className: `dropzone ${className}` })}>
      <input {...getInputProps()} />
      <button className="h-full w-full">
        <div className="flex h-48 w-full flex-col items-center justify-center rounded-xl border-[1px] border-dashed border-base-400 bg-base-200">
          <p className="text-[80px]">
            <CloudArrowUp size={96} />
          </p>
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            Drop your files here or click to upload
          </p>
          <aside>
            <ul>{files}</ul>
          </aside>
        </div>
      </button>
    </div>
  );
};

export default FileUpload;
