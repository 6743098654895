import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";

export interface Speak {
  id: string;
  transcriptionStatus: "waiting" | "processing" | "finished";
  title?: string;
  description?: string;
  userId: string;
  videoPath: string;
  duration?: number;
  speakerNames?: string[];
  numberOfSpeakers?: number;
  language: string;
  createdAt: Timestamp;
  finishedAt?: Timestamp;
  transcription?: Transcription;
  videoUrl?: string;
}

export interface Transcription {
  id: string;
  prompt: string;
  content: string;
  createdAt: Timestamp;
  finishedAt?: Timestamp;
}

export const speakConverter: FirestoreDataConverter<Speak> = {
  toFirestore(_: Speak): DocumentData {
    // 現状、使わない方向
    throw new Error("never used for now")
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options: SnapshotOptions
  ): Speak {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      title: data.title,
      description: data.description,
      speakerNames: data.speakerNames,
      numberOfSpeakers: data.numberOfSpeakers,
      transcriptionStatus: data.transcriptionStatus,
      userId: data.userId,
      duration: data.duration,
      createdAt: data.createdAt,
      videoPath: data.videoPath,
      language: data.language
    };

  }
};


export const transcriptionConverter: FirestoreDataConverter<Transcription> = {
  toFirestore(_: Transcription): DocumentData {
    // 現状、使わない方向
    throw new Error("never used for now")
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options: SnapshotOptions
  ): Transcription {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      prompt: data.prompt,
      content: data.content,
      createdAt: data.createdAt,
      finishedAt: data.finishedAt
    };
  }
};