import "./init";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const storage = getStorage();

let storageEmulatorHost =
  process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_ADDRESS;

if (storageEmulatorHost) {
  const [host, port] = storageEmulatorHost.split(":");
  connectStorageEmulator(storage, host, parseInt(port));
}

export { storage };
