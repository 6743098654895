import React from "react";
import { useTheme } from "../../contexts/ThemeProvider";
import { Sun, Moon } from "@phosphor-icons/react";

interface ChangeDarkModeProps {
  className?: string;
  size?: number;
}

const ChangeDarkMode: React.FC<ChangeDarkModeProps> = ({ className, size }) => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <button onClick={toggleDarkMode}>
      {isDarkMode ? (
        <Sun className={className} size={size} />
      ) : (
        <Moon className={className} size={size} />
      )}
    </button>
  );
};

export default ChangeDarkMode;
