import { FC } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import SpeakList from "../../features/speaks/List";
import { useTranslation } from "react-i18next";
import { Plus } from "@phosphor-icons/react";

const ListPage: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout requiredAuth={true}>
      <div className="flex justify-between mb-4 mt-6">
        <h1 className="text-xl font-bold mb-4">
          {t("speak-list.title")}
        </h1>

        <a
          className="btn btn-primary btn-sm"
          href="/speaks/new"
        >
          <Plus className="h-4 w-4" />
          {t("speak-list.add-btn")}
        </a>
      </div>
      <SpeakList />
    </PageLayout>
  );
};

export default ListPage;
