import { addDoc, collection, DocumentData, DocumentReference, serverTimestamp, updateDoc } from "firebase/firestore";
import { firestore } from "../services/firebase/firestoreService";

export const createSpeak = async (title: string,
  description: string,
  numberOfSpeakers: number,
  speakerNames: string[],
  language: string,
  userId: string) => {
  const ref = collection(firestore, 'speaks')
  return await addDoc(ref, {
      title,
      description,
      numberOfSpeakers,
      speakerNames,
      transcriptionStatus: "waiting",
      userId,
      language: language || "ja",
      createdAt: serverTimestamp()
  })
}

export const setPathToSpeak = async (
  ref: DocumentReference<DocumentData>,
  videoPath: string,
) => {
  await updateDoc(ref, { videoPath });
}