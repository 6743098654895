import { FC } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import NewForm from "../../features/speaks/NewForm";
import { useNavigate } from "react-router-dom";
import { Divider } from "react-daisyui";

const NewPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout requiredAuth={true}>
      <h1 className="text-xl font-bold mt-4">
        書き起こし
      </h1>
      <Divider />
      <NewForm onFinished={() => navigate("/speaks")} />
    </PageLayout>
  );
};

export default NewPage;
