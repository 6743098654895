import { FC } from "react";
import PageLayout from "../components/layouts/PageLayout";

const PublicPage: FC = () => (
  <PageLayout>
    <div>It's public page.</div>
  </PageLayout>
);

export default PublicPage;
